import * as apifetch from './fetch.js';

export async function monitorOrderData(order_id, client_profile) {
    const urlEndpoint = '/monitoring/monitorOrderData.php';
    
    const postData = {
        order_id: order_id,
        profile: client_profile
    };

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function monitorStartPayment(order_id, device, email) {
    const urlEndpoint = '/monitoring/monitorStartPayment.php';
    
    const postData = {
        order_id: order_id,
        email: email,
        mobile: device.mobile ? 'mobile' : 'desktop',
        operating: device.operating ? 'iOS' : 'not iOS',
        browserName: device.browserName, 
        browserVersion: device.browserVersion 
    };

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}