import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

import Template from '../templates/template';

import BlockCategoriesPreviews from '../components/block/block-categories-previews';

const Genres = inject("stores") (
    observer (
        class Genres extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
            }
      
            render() {
                return (
                    <Template
                        type = 'default'
                        reset = {false}
                        sticky = {false}
                        header = {"cats_all"}
                    > 
                        <div className="content-wrapper">
                            <div className="content-wrapper__content">
                                <BlockCategoriesPreviews
                                    shop = {this.storeGeneral.shop}
                                    expanded = {true}
                                    active_togglenav = {this.storeUi.active_togglenav}
                                />
                            </div>
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default Genres;
