import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const BlockCatsPopular = inject("stores") (
    observer (
        class BlockCatsPopular extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;

                this.state = {};
            }

            componentDidMount = async() => {
                const catsPopular = await this.storeBooks.getCategoriesPopular();

                this.setState({
                    listCategoriesPopular: catsPopular
                })
            }

            handleOnClickCat = async(cat) => { 
                let cat_name;
                if (this.storeGeneral.shop === 'SHOP_ALL') {
                    cat_name = cat.category;
                } else {
                    cat_name = cat.name;
                }

                if (this.props.shop) {
                    this.storeGeneral.setActiveShop(this.props.shop);
                    //SbC depricated??
                    this.storeGeneral.setShop(this.props.shop);
                }

                this.storeUi.setActiveSubCategory(
                    (cat_name !== this.storeUi.active_category || (cat_name === this.storeUi.active_category && this.storeUi.active_subcategory === '')) ? cat_name : ''
                );
                if (cat_name !== this.storeUi.active_category ) {
                    this.storeUi.setActiveCategory(cat_name);
                    this.storeUi.setPageView('page', 1);
                } else {
                    this.storeUi.setActiveSubCats(cat_name);
                }

                let url = "/category/" + cat_name;
                this.props.history.push(url);
            }

            handleGoto = (url) => {          
                this.props.history.push(url);
            }
  
            render() {
                return (
                    <div className="block block--popular">
                        <div className="block--popular__block-title">
                            <div className="block--popular__block-title--main">
                                <span className="blocks__blocks-title--main-section">Alle boeken</span>
                                <span 
                                    className="blocks__blocks-title--main-cat"
                                >
                                    {i18n.t("block.cats_popular.header")}
                                </span>
                            </div>
                        </div> 
                        <div className="block--popular__content">
                            <div className="block--popular__content-list">
                                <ul className="list--cats-popular">

                                    {this.state.listCategoriesPopular && this.state.listCategoriesPopular.map((cat) => (
                                        cat.shop.includes(this.storeGeneral.shop) &&
                                            <li 
                                                key = {cat.category}
                                                className="list--cats-popular__listitem --link"
                                                onClick={(e) => this.handleOnClickCat(cat)}
                                            >
                                                <div
                                                    className="list--cats-popular__listitem-bg" 
                                                    style={{ backgroundImage: `url('` + this.urlAPI  + `/public/images/genres/` + cat.img + `')`}}
                                                />
                                                <div 
                                                    className="list--cats-popular__listitem-fade bg-gradient-textemph" />
                                                <span 
                                                    className="list--cats-popular__listitem-title"
                                                    dangerouslySetInnerHTML={{ __html: cat.displayValue}}
                                                />
                                            </li>
                                    ))}
                                </ul>

                                <div 
                                    className = "blocks__list--actions blocks__list--action-icons"
                                    onClick={(e) => this.handleGoto('/genres')}
                                >
                                    <div class="blocks__list--action">
                                        <span 
                                            className="link--read-more"
                                            dangerouslySetInnerHTML={{ __html: i18n.t("block.categories.link_all")}}
                                        />
                                        <div className="paging-forward-wrapper --next"><div class="paging-forward-wrapper__icon"></div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(BlockCatsPopular));


