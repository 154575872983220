import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";

import * as ui from '../utilities/ui';

import CartProcessSteps from '../components/cart/cart-steps';
import CartLogin from '../components/cart/cart-login';
import CartProfile from '../components/cart/cart-profile';
import CartPayment from '../components/cart/cart-payment';
import CartReview from '../components/cart/cart-review';

const CartProcess = inject("stores") (
    observer (
        class CartProcess extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    cart_view: "client"
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;
            }

            componentDidMount = () => {
                ui.handleScroll('top');
                //this.storeUi.setUseCase("inorder");
                this.storeUi.setTemplateView("clean");
            }

            componentDidUpdate() {
                ui.handleScroll('top');
            }

            componentWillUnmount = () => {
                this.storeUi.setTemplateView();
            }

            cartView(view) {
                switch(view) {
                    case "client": return <CartProfile />
                    case "login": return <CartLogin />
                    case "payment": return <CartPayment />
                    case "review": return <CartReview 
                                            isLoggedin = {this.storeUser.user_data.is_loggedin} 
                                        />
                }
            }

            

            render() {
                let cartView = this.storeOrder.order_interface.cart_view;

                return (
                    <div className="cart">
                        <CartProcessSteps 
                        
                        />
                        {this.cartView(cartView)}
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(CartProcess));
