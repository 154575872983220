import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import * as content from '../../utilities/content';

import BlockPreviewAudio from '../block/block-preview-audio';
import BlockPreviewPdf from '../block/block-preview-pdf';
import BlockPreviewPdfExtra from '../block/block-preview-pdf-extra';
import BlockPreviewVideo from '../block/block-preview-video';
import SocialLink from '../../elements/social-link';
import BlockSocialSharing from '../block/block-social-sharing';
import FormSendToFriend from '../../components/forms/form-sendtofriend';

const BlockBookTools = inject("stores") (
    observer (
        class BlockBookTools extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    social_links: [
                        'email'
                    ],
                    audio_view: false,
                    video_view: false,
                    form_view: false,
                    medium: 'email',
                    has_downloads: false,
                    has_audio: false,
                    has_video: false,
                    play_audio: false,
                    play_video: false,
                    social_tooltip: i18n.t("share.book"),
                    type: 'boek'
                }
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.urlPublic = process.env.REACT_APP_URL_PUBLIC;

                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
            }

            async componentDidMount() {
                await this.setTools();
                this.setDefaultTooltip();
                const downloads = this.storeBooks.getBookDownloads(this.props.book.sku);

                if (this.storeBooks.list_downloads.length > 0) {
                    this.setState({
                        has_downloads: true
                    })
                }
            }

            async componentDidUpdate(prevProps) {
                if (prevProps.book.sku !== this.props.book.sku) {
                    await this.setTools();
                    this.setDefaultTooltip();
                }
            }

            async setTools() {
                const isbn = this.props.book.sku;

                const audio = await this.storeBooks.getPreview('audio', isbn);
                const video = await this.storeBooks.getPreview('video', isbn);

                this.setState({
                    has_audio: this.storeBooks.preview_audio.length > 0 ? true : false,
                    has_video: this.storeBooks.preview_video.length > 0 ? true : false
                })
            }

            handleLinkPdf = (sku) => {
                const url = this.urlPublic + "/public/preview-pdf/" + sku + ".pdf";
                window.open(url);
            }

            handleLinkDownloads = (e) => {
                this.props.viewDownloads(e);
            }

            handleLinkAudio = (medium) => {
                this.setState({
                    audio_view: !this.state.audio_view,
                    form_view: false,
                    video_view: false,
                    medium: medium,
                    play_audio: false,
                    play_video: false
                })
            }

            handleLinkVideo = (medium) => {
                this.setState({
                    audio_view: false,
                    form_view: false,
                    video_view: !this.state.video_view,
                    medium: medium,
                    play_audio: false,
                    play_video: false
                })
            }

            handleLinkSocial = (medium) => {
                this.setState({
                    audio_view: false,
                    form_view: !this.state.form_view,
                    video_view: false,
                    medium: medium,
                    play_audio: false,
                    play_video: false
                })
            }

            setDefaultTooltip = () => {
                const type = content.setContentType(this.props.book)

                this.setState({
                    type: type,
                    social_tooltip: 'Deel dit ' + type
                })

                return type;
            }

            onMouseEnterSocial = (social) => {
                this.setState({
                    social_tooltip: (social !== '' && social !== undefined) 
                        ?   social 
                        :   ('Deel dit ' + this.setDefaultTooltip())
                })
            }
  
            render() {             
                return (
                    <React.Fragment>
                        <div className="block block--book-tools">
                            <div className="book-tools__group">
                                <BlockPreviewPdf
                                    sku = {this.props.book.sku}
                                    label = {true}
                                />
                                <BlockPreviewPdfExtra
                                    sku = {this.props.book.sku}
                                    label = {true}
                                />
                                {this.state.has_audio &&
                                    <div className="book-tools__tool" onClick={(e) => this.handleLinkAudio(e)} >
                                        <div className="book-tools__tool-link book-tools__tool-link--audio --link" />
                                        <span className="book-tools__tool-title --link">{i18n.t("book_tools.audio")}</span>
                                    </div> 
                                }
                                {this.state.has_video &&
                                    <div className="book-tools__tool" onClick={(e) => this.handleLinkVideo(e)} >
                                        <div className="book-tools__tool-link book-tools__tool-link--video --link" />
                                        <span className="book-tools__tool-title --link">{i18n.t("book_tools.video")}</span>
                                    </div>
                                }
                                {this.state.has_downloads &&
                                    <div 
                                        className="book-tools__tool" 
                                        onClick={(e) => this.handleLinkDownloads(e)} 
                                    >
                                        <div className="book-tools__tool-link book-tools__tool-link--downloads --link" />
                                        <span className="book-tools__tool-title --link">{i18n.t("book_tools.downloads")}</span>
                                    </div>
                                }
                            </div>
                            <div className="book-tools__group">
                                <div className="book-tools__group--social">
                                    <div className="book-tools__tool">
                                        <div className="social-links">
                                            <BlockSocialSharing 
                                                type = 'book'
                                                sku = {this.props.book.sku} 
                                                onMouseEnter = {this.onMouseEnterSocial}
                                            />
                                        </div>
                                        <span className="book-tools__tool-title">{this.state.social_tooltip}</span>
                                    </div>
                                </div>
                                <div className="book-tools__group--social">
                                    <div className="book-tools__tool book-tools__tool--email">
                                        <div className="social-links">
                                            {this.state.social_links.map((link) => (
                                                <SocialLink 
                                                    medium = {link}
                                                    handleSocialLink = {(e) => this.handleLinkSocial(link)} 
                                                />
                                            ))}
                                        </div>
                                        <span className="book-tools__tool-title --link" onClick={(e) => this.handleLinkSocial('email')}
                                            dangerouslySetInnerHTML={{ __html: i18n.t("book_tools.tell_a_friend", {type: this.state.type})}}>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {this.state.has_audio &&
                            <div className={"block-audio--foldout " + (this.state.audio_view ? "--expanded" : "--collapsed")}>
                                <BlockPreviewAudio
                                    book = {this.props.book}
                                    blockTitle = {i18n.t("block.preview_audio.header")}
                                    play = {this.state.play_audio}
                                />
                            </div>
                        }
                        
                        {this.state.has_video &&
                            <div className={"block-video--foldout " + (this.state.video_view ? "--expanded" : "--collapsed")}>
                                <BlockPreviewVideo
                                    book = {this.props.book}
                                    blockTitle = {i18n.t("block.preview_video.header")}
                                    play = {this.state.play_video}
                                />
                                {/* <BlockPreviewVideoIFrame
                                    book = {this.props.book}
                                    blockTitle = {i18n.t("block.preview_video.header")}
                                    play = {this.state.play_video}
                                /> */}
                            </div>
                        }

                        <div className={"block-social--foldout " + (this.state.form_view ? "--expanded" : "--collapsed")}>
                            {this.state.medium === "email" && 
                                <div className="segment__content--form">
                                    <p>Het is de moeite waard om een vriend, collega of familielid op dit boek te attenderen. Je kunt dat doen door onderstaande velden in te vullen en te versturen. Daarmee geef je de ontvangers de mogelijkheid om gratis van diverse boeken het eerste hoofdstuk te lezen.</p>
                                    <FormSendToFriend />
                                </div>
                            }
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(BlockBookTools);


