import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import * as ui from '../../utilities/ui';

import BlockCover from '../block/block-cover';

const CategoriesPreviewsBooks = inject("stores") (
    observer (
        class CategoriesPreviewsBooks extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeCatalogs = this.props.stores.storeCatalogs;

                this.state = {
                    bookList: []
                }
            }

            componentDidMount = async() => {
                const catName = this.props.category;
                const bookList = await this.storeBooks.returnBooksForCategory(catName, this.storeUi.page_view);

                this.setState({
                    bookList: bookList
                })
            }

            componentDidUpdate = async(prevProps) => {

            }

            handleOnClickCat = async(cat_name) => { 
                let id;

                ui.smoothScrollTo(0, 0, 500); 
                this.storeUi.setFreezeScrolling(false);
                this.storeUi.setFreezePos(0);

                this.storeUi.setActiveProductGroup('');
                this.storeUi.setActiveSubGroup('');

                if (this.storeGeneral.shop === 'SHOP_SB') {
                    id = 2;
                 } else {
                    id = 3;
                 }
                this.storeUi.setActiveToggleNav(id);

                this.storeCatalogs.setActiveCategory(cat_name);

                this.storeUi.setActiveSubCategory(
                    (cat_name !== this.storeUi.active_category || (cat_name === this.storeUi.active_category && this.storeUi.active_subcategory === '')) ? cat_name : ''
                );
                               
                if (cat_name !== this.storeUi.active_category ) {
                    this.storeCatalogs.setActiveCategory(cat_name);
                    this.storeUi.setActiveCategory(cat_name);

                    this.storeCatalogs.setActiveSubCategory('');                  
                    this.storeUi.setActiveSubCategory('');
                    
                    this.storeUi.setPageView('page', 1);
                } else {
                    this.storeUi.setActiveSubCats(cat_name);
                }
               
                const htmlEntities = {
                    "&euml;": "ë"
                };
                cat_name = cat_name.replace('&euml;', match => htmlEntities[match]);

                this.storeUi.setNavMobile(false);
                let url = "/categorie/" + cat_name;
                this.props.history.push(url);
            }

            render() {
                return (
                    <div className="list list--previewcat">                       
                        <ul 
                            className="blocks__list blocks__list--covers blocks__list--covers-foldout --collapsed "
                        >
                            {this.state.bookList && this.state.bookList.map((book, i) => (
                                book && i < 5 &&
                                    <li 
                                        key={i} 
                                        className="block block--book" 
                                        onClick={(e) => this.handleOnClick(e, book)}
                                    >
                                        <BlockCover 
                                            book = {book}
                                            sku = {book.sku}
                                            ranking = {this.props.ranking} 
                                        />
                                    </li>
                            ))}
                        </ul>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(CategoriesPreviewsBooks));

