import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import i18n from "i18next";

import * as utilities from '../../utilities/formats';

import ImgCover from '../../elements/img-cover';
import FeedbackOrderLineDiscount from '../feedback/feedback-orderline-discount';

const CartReviewProduct = inject("stores") (
    observer (
        class CartReviewProduct extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;

                this.state = {

                }
            }

            componentDidMount = async() => {

            }

            handleScroll = () => {
                window.scrollTo(0, 0);
            }

            calculateRowPrice = (unit_price) => {
                let total = 0;
                let calculatedDiscount = 0;

                if (this.storeAuth.user.is_loggedin) {
                    calculatedDiscount = this.state.discountPerc;
                }

                if (unit_price) {
                    let price = unit_price * this.state.formControls.amount.value * (1 - (calculatedDiscount / 100));
                    price = Math.round(price * 100) / 100;
                    total = utilities.formatPrice(price);
                    this.storeOrder.saveRowPrice(this.storeUser.user_data.is_loggedin, this.props.product.id, this.props.product.sku, total, calculatedDiscount);
                } else {
                    total = 'UNKNOWN';
                }

                return utilities.displayPrice(total);
            }

            displayPrice = (price) => {
                const total = utilities.formatPrice(price);
                return utilities.displayPrice(total);
            }

            render() {
                return (
                    <li key={this.props.key} className="products-table__row">
                        <div className="products-table__row--details">
                            <div className="products-table__cell products-table__cell--details-wrapper">
                                <div 
                                    className={"products-table__cell--details-content" + (this.props.editable === true ? " --link" : "")} 
                                    onClick={() => this.handleOnClick(this.props.product.sku)}
                                >
                                    <div className="products-table__cell products-table__cell--sku">
                                        <ImgCover
                                            sku = {this.props.product.sku}
                                        />
                                    </div>
                                    <div className="products-table__cell products-table__cell--title"
                                        dangerouslySetInnerHTML={{ __html: this.props.product.description}}
                                    />
                                </div>
                            </div>
                            <div className="products-table__cell products-table__cell--price-wrapper">
                                <div className="products-table__cell products-table__cell--amount">
                                    <span className="amount">
                                        {this.displayPrice(this.props.product.price)}
                                    </span>
                                </div>
                                <div className="products-table__cell products-table__cell--count">
                                    {this.props.product.amount}
                                </div>
                                <div className="products-table__cell products-table__cell--amount"><span className="amount">{this.props.product.product_total_incl}</span></div>
                            </div>
                        </div>

                        {this.props.product.status !== 'deletedx' && parseInt(this.props.product.discount) !== 0 &&
                            <div className="products-table__row--discount --has-discount">
                                Er is op dit product een korting van {this.props.product.discount}% toegepast.
                            </div>
                        }
                    </li>
                )
            }
        }
    )
)

export default withRouter(CartReviewProduct);
