import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as order from '../communicator/order';
import * as tools from '../communicator/tools'

class StoreShipping {

    constructor(storeRoot) {
        this.storeRoot = storeRoot;
        this.storeAuth = this.storeRoot.storeAuth;
        this.storeUser = this.storeRoot.storeUser;
        this.storeOrder = this.storeRoot.storeOrder;
    }

    async calculateShipping(orderId) {
        let shipping = 0;
        let shipping_abroad = false;
        let weight = 0;
        let packageType = 2;

        const userId = this.storeAuth.user.is_loggedin ? this.storeAuth.user.user_id : 0;

        const hasNoShippingCustomer = this.checkNoShippingCustomer(userId);
        const hasNoShippingOrder = await this.checkNoShippingOrder(orderId);
        const hasShippingOrderOverruled = this.checkShippingOrderOverruled(orderId);

        if (hasNoShippingCustomer) {
            shipping = 0;
        }

        if (hasNoShippingOrder) {
            shipping = 0;
        }
        
        if (hasShippingOrderOverruled) {
            shipping = await this.getShippingOrderOverruled(orderId);
        }

        if (!hasNoShippingCustomer && !hasNoShippingOrder && !hasShippingOrderOverruled) {
            if (userId !== 0) {
                shipping_abroad = this.checkShippingCountry();
            }

            weight = this.getWeight();

            if (!Object.is(weight, NaN)) {
                if (weight <= 0) {
                    // SbC only downloads
                    shipping = 0;
                } else {
                    if (shipping_abroad) {
                        shipping = await this.getShippingCostsAbroad(weight);
                    } else {
                        packageType = this.getPackageType(weight);

                        shipping = await this.getShippingCosts(orderId, weight, packageType);
                        shipping = this.checkFreeShippingBoundaries(orderId, shipping);
                    }
                }
            }
            
        }

        const vatPerc = this.calculateShippingVat();
        this.storeOrder.setShippingCosts(shipping);
        this.storeOrder.setShippingVAT(vatPerc);
        this.storeOrder.calculateShippingCostsTotal();

        /* 
         * SbC save in orderlines
         */
        if (!hasShippingOrderOverruled) {
            const returnData = await this.storeOrder.editOrderLineShipping();
        }

        return shipping;
    }

    checkShippingCountry() {
        let shipping_abroad = false;
        let countryCode = 'NL';

        if (this.storeAuth.user.is_loggedin) {
            countryCode = this.storeUser.user_data.profile.country_code;
        } else {
            countryCode = this.storeOrder.order_data.delivery_address.country_code;
        }

        if (this.storeOrder.order_data.client_settings.has_delivery_address === true ) {
            countryCode = this.storeOrder.order_data.delivery_address.country_code;
            shipping_abroad = countryCode === 'NL' ? false : true;
        } else {
            shipping_abroad = countryCode === 'NL' ? false : true;
        }

        // if (this.storeOrder.order_data.client_settings.has_delivery_address === true ) {
        //     shipping_abroad = this.storeOrder.order_data.delivery_address.country_code === 'NL' ? false : true;
        // } else {
        //     if (this.storeAuth.user.is_loggedin) {
        //         shipping_abroad = this.storeUser.user_data.profile.country_code === 'NL' ? false : true;
        //     } else {
        //         shipping_abroad = this.storeOrder.order_data.client_profile.country_code === 'NL' ? false : true;
        //     }
        // }

        return shipping_abroad;
    }

    checkNoShippingCustomer(userId) {
        let noShipping = false;

        if (this.storeAuth.user.no_shipping_costs === 1 || this.storeAuth.user.no_shipping_costs === '1') {
            noShipping = true;
        };

        return noShipping;
    }

    checkShippingCustomerOverruled(userId) {
        let isOverruled = false;

        if (this.storeAuth.user.no_shipping_costs === 1 || this.storeAuth.user.no_shipping_costs === '1') {
            isOverruled = true;
        };

        return isOverruled;
    }

    checkNoShippingOrder = async(orderId) => {
        let noShipping = false;

        try {
            const returnData = await order.checkNoShippingOrder(orderId);
            runInAction(() => {
                const value = returnData.data[0].no_shipping_costs;

                if (value === 1) {
                    noShipping = true;
                } 

                return noShipping;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return noShipping;
    }

    checkShippingOrderOverruled(orderId) {
        let isOverruled = false;

        if (this.storeOrder.order_settings.custom_shipping_costs === 1) {
           isOverruled = true;
        }

        return isOverruled;
    }

    // getShippingCustomerOverruled(userId) {
    //     let shipping;

    //     if (this.storeAuth.user.no_shipping_costs === 1 || this.storeAuth.user.no_shipping_costs === '1') {
    //         shipping = 0;
    //     };

    //     return shipping;
    // }

    checkFreeShippingBoundaries(orderId, shipping) {
        let evaluatedShipping = shipping;
        let totalCart = 0;
        let discountApplied = false;

        let items = [];
        const loggedin = this.storeOrder.order_data.client_id !== 0 ? true : false;
        if (loggedin) {
            items = this.storeOrder.cart.user;
        } else {
            items = this.storeOrder.cart.anonymous;
        }

        items.map((product) => {
            if (product.status !== 'deletedx' && product.amount > 0) {
                discountApplied = (product.discount > 0) ? true : discountApplied; 
                discountApplied = (product.discount > 0 && this.storeOrder.order_settings.discounts_overruled === 1) ? true : discountApplied;
                
                totalCart = (discountApplied) 
                    ?   totalCart + parseFloat(product.price)  * ((100 - product.discount)/100) * parseFloat(product.amount)
                    :   totalCart + parseFloat(product.price)  * parseFloat(product.amount)
            }
        })

        if (totalCart <= 0) {
            evaluatedShipping = 0;
        }

        if (!discountApplied && totalCart >= this.storeOrder.order_settings.shipping_free) {
            evaluatedShipping = 0;
        }

        //SbC free above 250 for all
        if (totalCart >= this.storeOrder.order_settings.shipping_free_resellers) {
            evaluatedShipping = 0;
        }

        return evaluatedShipping;
    }

    getShippingOrderOverruled = async(orderId) => {
        let shipping;

        if (this.storeOrder.order_settings.custom_shipping_costs === '1' || this.storeOrder.order_settings.custom_shipping_costs === 1) {
            shipping = await this.getCustomShippingCosts(orderId);
        }

        return shipping;
    }

    getCustomShippingCosts = async(orderId) => {
        let custom_shipping = 0;
        try {
            const returnData = await order.getCustomShippingCosts(orderId);
            runInAction(() => {
                custom_shipping = parseFloat(returnData.data[0].custom_shipping_costs) / (1 + parseFloat(returnData.data[0].shipping_vat) / 100);

                return custom_shipping;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return custom_shipping;
    }

    getWeight() {
        let weight = this.calculateWeight;

        return weight;
    }

    getPackageType(weight) {
        // SbC default is as_letter
        let packageType = 2; 

        let items = [];
        const loggedin = this.storeOrder.order_data.client_id !== 0 ? true : false;
        if (loggedin) {
            items = this.storeOrder.cart.user;
        } else {
            items = this.storeOrder.cart.anonymous;
        }

        items.map((product) => {
            //SbC check if package type is overruled for bottles etc
            if (product.package_type === 1 || product.weight === 1) {
                packageType = 1;
            }
        });

        if (items.length >= 3 && weight > 1000) {
            packageType = 1;
        }

        if (weight > 1000) {
            packageType = 1;
        }

        return packageType;
    }

    getShippingCosts = async(orderId, weight, packageType) => {
        //SbC arbitrary default value
        let shipping = 8.45;
        let shippingData;

        let returnData;
        try { 
            //SbC always based on weight
            //SbC CHECK what if digital products only like MP3 of event ticket?

            //if (orderId === 0 || orderId === '0' || parseInt(orderId) === 99) {
                returnData = await tools.getShippingCosts(weight);
            // } else {
            //SbC DEPRICATED as with new order in review first sometimes no orderlines yet, resulting in only package weight of 80
            //     returnData = await tools.getShippingCostsV2(orderId);
            //     console.log('SbC return shipping data v2', weight, orderId, returnData.data[0])
            // }
            shippingData = returnData.data[0];

            runInAction(async() => {
                if (shippingData) { 
                    shipping = (shippingData.as_letter !== null && packageType !== 1)
                    ?   shippingData.as_letter <= shippingData.as_package 
                        ?   shippingData.as_letter 
                        :   shippingData.as_package
                    :   shippingData.as_package
                }
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }   

        return shipping;
    }

    getShippingCostsAbroad = async(weight) => {
        //SbC arbitrary default value
        let shipping = 8.45;
        let surcharge = 0;

        const countriesList = this.storeOrder.countriesList;

        let idx = -1;
        if (this.storeOrder.order_data.client_settings.has_delivery_address === true) {
            idx = countriesList.findIndex(country => country.code == this.storeOrder.order_data.delivery_address.country_code);
        } else {
            if (this.storeAuth.user.is_loggedin) {
                idx = countriesList.findIndex(country => country.code == this.storeUser.user_data.profile.country_code);
            } else {
                idx = countriesList.findIndex(country => country.code == this.storeOrder.order_data.client_profile.country_code);
            }
        }
       
        //SbC calculate weight surcharge if applicable
        if (idx !== 0) {
            shipping = countriesList[idx].base_shipping;
        
            if (countriesList[idx].surcharge_weight !== null && countriesList[idx].surcharge_weight !== '') {
                surcharge =  weight/1000 * parseFloat(countriesList[idx].surcharge_weight);
                shipping = parseFloat(shipping) + surcharge;
            }
        }

        return shipping;
    }

    calculateShippingVat() {
        let items = [];
        const loggedin = this.storeOrder.order_data.client_id !== 0 ? true : false;
        if (loggedin) {
            items = this.storeOrder.cart.user;
        } else {
            items = this.storeOrder.cart.anonymous;
        }
        
        let vat_perc = 9;
        items.map((product) => {
            if (product.amount !== 0 && product.vat_perc > vat_perc) {
                vat_perc = product.vat_perc;
            }
        })

        return vat_perc;
    }

    get calculateWeight() {
        let weight = 0;
        let weight_box = 80;
        if (this.storeOrder.settings_list["weightBox"] !== undefined) {
            weight_box = parseInt(this.storeOrder.settings_list["weightBox"]);
        }

        let items = [];
        const loggedin = this.storeOrder.order_data.client_id !== 0 ? true : false;
        if (loggedin) {
            items = this.storeOrder.cart.user;
        } else {
            items = this.storeOrder.cart.anonymous;
        }

        weight = weight + weight_box;

        items.map((product, i) => {
            if (product.status !== 'deletedx' && product.amount > 0) {
                if (product.weight === null || product.weight === '') {
                    //SbC to prevent miscalculation in case no weight set
                    
                    let defaultWeight = 300;
                    weight = weight + defaultWeight;
                } else {
                    weight = weight + product.amount * parseInt(product.weight);
                }
                
                return weight;
            }
        })

        return weight;
    }
}

decorate(StoreShipping, {
    calculateShipping: action,
    calculateWeight: computed
})

export default StoreShipping;