import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import i18n from "i18next";

import * as utilities from '../../utilities/formats';

import CartProduct from './cart-product';
import CartReviewProduct from './cart-review-product';
import ContentText from '../../components/content/content-text';

const CartReview = inject("stores") (
    observer (
        class CartReview extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;
                this.storeShipping = this.props.stores.storeShipping;

                this.sectionProfile = ["firstname", "lastname", "email", "phonenr"];
                this.sectionAddress = ["company", "address", "housenr", "addition", "zipcode", "city", "country_select"];
                this.sectionDeliveryAddress = ["delivery_name", "delivery_company", "address", "housenr", "addition", "zipcode", "city", "country_select"];

                this.state = {
                    btn_class: '--disabled',
                    login: false
                }
            }

            componentDidMount = async() => {
                const userId = this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.user_id : 0;

                //this.storeOrder.calculateShipping(userId);
                let orderId = this.storeOrder.order_data.order_id;
                const shipping = await this.storeShipping.calculateShipping(orderId);
                
                //SbC shipping might be changed after login when user has discounts
                //(async() => await this.storeOrder.editOrderItems() )();

                //this.handleScroll();
                this.storeUi.setReturnState('review');

                //console.log('SbC send to MSP::', this.storeOrder.order_data.shopping_cart.cart_total_incl, this.storeOrder.order_data.shipping_costs)
            }

            componentDidUpdate = async(prevProps) => {
                let orderId;

                if (prevProps.isLoggedin !== this.props.isLoggedin) {
                    orderId = this.storeOrder.order_data.order_id;
                    const shipping = await this.storeShipping.calculateShipping(orderId);
                }
            } 

            handleScroll = () => {
                window.scrollTo(0, 0);
            }

            setCartView = async(view) => {
                //SbC prevent overwriting closed order when on multiple devices
                let order_availability = await this.storeOrder.getOrderAvailability();
                if (order_availability === 'closed') {
                   let order_id = await this.storeOrder.createNewOrder(this.storeOrder.order_data.client_id, this.storeOrder.order_data.order_remark);
                   (async() => await this.storeOrder.saveOrderDataProfile() )();
                }

                const id = (this.storeUser.user_data.is_loggedin) ? this.storeUser.user_data.user_id : 0;
                if (view === 'cart') {
                    this.storeOrder.setCartView(id, 'client');
                    this.storeUi.setUseCase("inshop");
                } else {
                    this.storeOrder.setCartView(id, view);
                    let url = "/shoppingcart/" + view;
                    this.props.history.push(url);
                }
                
                this.handleScroll();
            }

            getShippingCountry = () => {
                let country_code = '';
                let countryDisplay = '';
                if (this.storeOrder.order_data.client_settings.has_delivery_address === true) {
                    country_code = this.storeOrder.order_data.delivery_address.country_code;
                } else {
                    country_code = this.storeOrder.order_data.client_profile.country_code;
                }

                if (this.storeOrder.countriesList[this.storeOrder.countriesList.findIndex(country => country.code == country_code)] !== undefined) {
                    countryDisplay = this.storeOrder.countriesList[this.storeOrder.countriesList.findIndex(country => country.code == country_code)].displayValue
                } else {
                    countryDisplay = this.storeOrder.countriesList[this.storeOrder.countriesList.findIndex(country => country.code == this.storeOrder.settings_list.isNL)].displayValue
                }

                return countryDisplay;
            }

            openTC = () => {
                this.storeUi.setContentComponent(
                    <ContentText
                        title = {i18n.t("page_headers.general_terms")}
                        page = {"terms-conditions"}
                        className = "content-scrollable"
                    />
                );
                this.storeUi.openDialogContent();
            }

            render() {
                const Key = (item) => {
                    const title = "forms.label." + item.item;
                    return (
                        <span className="list--dd__dt">{i18n.t(title)}</span>
                    )
                }

                return (
                    <div className="cart-data">
                        <div className="content-section cart-data__section--cart">
                            <h3>Controleer je bestelling</h3>
                            <ul className="cart-data__table">
                                {this.storeOrder.cart.user.map((product, i) => (
                                    (product.status !== 'deletedx' && product.amount !== 0 && product.sku !== 0 && product.sku !== undefined) &&
                                        // <CartProduct
                                        //     key = {i}
                                        //     product = {product}
                                        //     sku = {product.sku}
                                        //     editable = {false}
                                        //     discount_overruled = {this.storeOrder.order_settings.discounts_overruled}
                                        // />
                                        <CartReviewProduct
                                            key = {i}
                                            product = {product}
                                            sku = {product.sku}
                                            editable = {false}
                                            discount_overruled = {this.storeOrder.order_settings.discounts_overruled}
                                        />
                                ))}
                                <li className="products-table__row">
                                    <div className="products-table__row--details">
                                        <div className="products-table__cell products-table__cell--details-wrapper products-table__cell--details-wrapper-shipping">
                                            <div className="products-table__cell products-table__cell--title">
                                                Verzendkosten
                                            </div>
                                            <span className="products-table__cell products-table__cell--title-subtitle">(Op basis van land en gewicht)</span>
                                            <span className="products-table__cell products-table__cell--subtitle">Naar&nbsp;
                                                {this.getShippingCountry()}
                                            </span>
                                        </div>
                                        <div className="products-table__cell products-table__cell--price-wrapper">
                                            <div className="products-table__cell products-table__cell--amount">
                                                {/* <span className="amount">{utilities.displayPrice(this.storeOrder.order_data.shipping_costs)}</span> */}
                                                <span className="amount">{utilities.displayPrice(this.storeOrder.shippingCosts)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="products-table__row">
                                    <div className="products-table__row--details">
                                        <div className="products-table__cell products-table__cell--details-wrapper  products-table__cell--details-wrapper-total">
                                            <div className="products-table__cell products-table__cell--title">
                                                Totaal
                                            </div>
                                        </div>
                                        <div className="products-table__cell products-table__cell--price-wrapper">
                                            <div className="products-table__cell products-table__cell--amount">
                                                <span className="amount">{utilities.displayPrice(this.storeOrder.calculateTotalOrder)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div className="cart-data__buttons">
                                <button className="btn btn--tertiary" onClick={(e) => this.setCartView("cart")}>Wijzig bestelling</button>
                            </div>
                        </div>

                        <div className="content-section cart-data__section--cart">
                            <h3>Persoonlijke gegevens</h3>
                            <ul>
                                {Object.keys(this.storeOrder.order_data.client_profile).map((key, i) => (
                                    <React.Fragment>
                                        {this.sectionProfile.indexOf(key) != -1 &&
                                            <li className="list--dd__listitem">
                                                <Key
                                                    item = {key}
                                                />
                                                <span className="list--dd__dd">{this.storeOrder.order_data.client_profile[key]}</span>
                                            </li>
                                        }
                                    </React.Fragment>
                                ))}
                            </ul>
                            <div className="cart-data__buttons">
                                <button className="btn btn--tertiary" onClick={(e) => this.setCartView("client")}>Wijzig persoonlijke gegevens</button>
                            </div>
                        </div>

                        <div className="content-section cart-data__section--cart">
                            <h3>Adres</h3>
                            <p>Factuuradres {!this.storeOrder.order_data.client_settings.has_delivery_address && <span>en afleveradres</span>}</p>
                            <ul>
                                {this.sectionAddress.map(key => (
                                    ((key !== 'company') || (key === 'company' && this.storeOrder.order_data.company_profile.name !== '' && this.storeOrder.order_data.company_profile.name !== null)) &&
                                     <li className="list--dd__listitem">
                                        <Key
                                            item = {key}
                                        />
                                        <span className="list--dd__dd">
                                            {key != 'country_select'
                                            ?   key !== 'company'
                                                ?   this.storeOrder.order_data.client_profile[key]
                                                :   this.storeOrder.order_data.company_profile.name
                                            :   this.storeOrder.countriesList[this.storeOrder.countriesList.findIndex(country => country.code == this.storeOrder.order_data.client_profile.country_code)] !== undefined
                                                ?   this.storeOrder.countriesList[this.storeOrder.countriesList.findIndex(country => country.code == this.storeOrder.order_data.client_profile.country_code)].displayValue
                                                :   this.storeOrder.countriesList[this.storeOrder.countriesList.findIndex(country => parseInt(country.code) == this.storeOrder.settings_list.isNL)].displayValue
                                            }
                                        </span>
                                    </li>
                                ))                                       
                                }
                            </ul>

                            {this.storeOrder.order_data.client_settings.has_delivery_address == true &&
                                <React.Fragment>
                                    <p>Afleveradres:</p>
                                    <ul>
                                        {this.sectionDeliveryAddress.map((key, i) => (
                                        // {Object.keys(this.storeOrder.order_data.delivery_address).map((key, i) => (
                                            <li className="list--dd__listitem">
                                                <Key
                                                    item = {key}
                                                />
                                                <span className="list--dd__dd">
                                                    {key != 'country_select'
                                                    ?   this.storeOrder.order_data.delivery_address[key]
                                                    :   this.storeOrder.countriesList[this.storeOrder.countriesList.findIndex(country => country.code == this.storeOrder.order_data.delivery_address.country_code)] !== undefined
                                                        ?   this.storeOrder.countriesList[this.storeOrder.countriesList.findIndex(country => country.code == this.storeOrder.order_data.delivery_address.country_code)].displayValue
                                                        :   this.storeOrder.countriesList[this.storeOrder.countriesList.findIndex(country => country.code == this.storeOrder.settings_list.isNL)].displayValue
                                                    }
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                </React.Fragment>
                            } 
                            <div className="cart-data__buttons">  
                                <button className="btn btn--tertiary" onClick={(e) => this.setCartView("client")}>Wijzig adresgegevens</button>
                            </div>
                        </div>

                        {this.storeOrder.order_data.client_settings.is_business == true &&
                            <div className="content-section">
                                <h3>Bedrijfsgegevens</h3>
                                <ul>
                                    <li className="list--dd__listitem">
                                        <Key
                                            item = {"company"}
                                        />
                                        <span className="list--dd__dd">
                                            {this.storeOrder.order_data.company_profile.name !== ''
                                                ?   this.storeOrder.order_data.company_profile.name
                                                :   '--'
                                            }
                                        </span>
                                    </li>
                                    <li className="list--dd__listitem">
                                        <Key
                                            item = {"nrkvk"}
                                        />
                                        <span className="list--dd__dd">
                                            {this.storeOrder.order_data.company_profile.nrKvK !== ''
                                                ?   this.storeOrder.order_data.company_profile.nrKvK
                                                :   '--'
                                            }
                                        </span>
                                    </li>
                                    <li className="list--dd__listitem">
                                        <Key
                                            item = {"nrvat"}
                                        />
                                        <span className="list--dd__dd">
                                            {this.storeOrder.order_data.company_profile.nrVAT !== ''
                                                ?   this.storeOrder.order_data.company_profile.nrVAT
                                                :   '--'
                                            }
                                        </span>
                                    </li>
                                </ul>

                                <div className="cart-data__buttons">  
                                    <button className="btn btn--tertiary" onClick={(e) => this.setCartView("client")}>Wijzig bedrijfsgegevens</button>
                                </div>
                            </div>
                        }

                        <div>
                            <span dangerouslySetInnerHTML={{ __html: i18n.t("forms.label.agreement_tc")}}></span>
                            <a href="#" onClick={(e) => this.openTC(e)}>algemene voorwaarden</a>
                            <span dangerouslySetInnerHTML={{ __html: i18n.t("forms.label.agreement_tc2")}}></span>
                        </div>

                        <div className="cart__actions">
                            <button className="btn btn--primary" onClick={(e) => this.setCartView("payment")}>{i18n.t("button.goto_payment")}</button>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withRouter(CartReview);
